<template>
  <div class="email-utils">
    <!-- Header Content -->
    <div class="header-site" style="font-size: 30px">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back
        </b-button>
      </router-link>
      Email List Utilities
    </div>

    <div class="widget-container">
      <h4>Email List Filtering</h4>

      <div class="list-container">
        <div class="left-column">
          <h5>Domain/Email Blacklists</h5>
          <input type="file" name="blacklist" accept=".csv" @change="addBlacklist" multiple />
        </div>

        <div class="right-column">
          <ul>
            <li v-if="blacklists.length === 0">
              Add a blacklist .CSV file to get started. These may be
              spreadsheets of email addresses or domain names, or both. You may
              upload multiple.
            </li>

            <li v-for="blacklist in blacklists" :key="blacklist.name">
              {{ blacklist.name }}
              <button @click="removeBlacklist(blacklist)">x</button>
            </li>
          </ul>
        </div>
      </div>

      <div class="list-container">
        <div class="left-column">
          <h5>Email Lists</h5>
          <input type="file" name="lists" accept=".csv" @change="addEmailList" multiple />
        </div>

        <div class="right-column">
          <ul>
            <li v-if="listsToFilter.length === 0">
              Add an email list .CSV file to get started. These may be
              spreadsheets of email addresses. You may upload multiple.
            </li>

            <li v-for="listToFilter in listsToFilter" :key="listToFilter.name">
              {{ listToFilter.name }}
              <button @click="removeEmailList(listToFilter)">x</button>
            </li>
          </ul>
        </div>
      </div>

      <div class="advanced-options">
        <b>Advanced Options</b>

        <section>
          <label for="injectTestRows">Inject Test Rows</label>
          <input type="checkbox" id="injectTestRows" v-model="injectTestRows" />
        </section>

        <div class="test-rows" v-if="injectTestRows">
          <textarea v-model="testRows" />
          <small>Note: These rows will be added to the top of the cleaned list. Any invalid CSV content here could
            potentially corrupt the cleaned list.</small>
        </div>
      </div>

      <button @click="generateLists" :disabled="isGenerating || listsToFilter.length === 0" class="generate-button">
        {{ isGenerating ? "Generating..." : "Generate Clean Email Lists" }}
      </button>

      <div class="error" v-if="errorMessages.length">
        {{ errorMessages.join("\n") }}
      </div>

      <div>
        This utility will remove any email addresses in the email lists that are
        found in the blacklists. Simply export both the blacklist & email lists
        as .CSV files and upload them here. Clicking the button will re-generate
        the email lists with blacklisted emails/domains removed.
      </div>
    </div>
  </div>
</template>

<script>
function parseCSV(str) {
  const arr = [];
  let quote = false; // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let row = 0, col = 0, c = 0; c < str.length; c++) {
    let cc = str[c],
      nc = str[c + 1]; // Current character, next character
    arr[row] = arr[row] || []; // Create a new row if necessary
    arr[row][col] = arr[row][col] || ""; // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc == '"' && quote && nc == '"') {
      arr[row][col] += cc;
      ++c;
      continue;
    }

    // If it's just one quotation mark, begin/end quoted field
    if (cc == '"') {
      quote = !quote;
      continue;
    }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc == "," && !quote) {
      ++col;
      continue;
    }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (cc == "\r" && nc == "\n" && !quote) {
      ++row;
      col = 0;
      ++c;
      continue;
    }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (cc == "\n" && !quote) {
      ++row;
      col = 0;
      continue;
    }
    if (cc == "\r" && !quote) {
      ++row;
      col = 0;
      continue;
    }

    // Otherwise, append the current character to the current column
    arr[row][col] += cc;
  }
  return arr;
}

function generateCsvFromArray(rows, filename) {
  let csvContent = "";

  for (const row of rows) {
    for (let i = 0; i < row.length; i++) {
      row[i] = `"${row[i]}"`;
    }

    csvContent += row.join(",") + "\n";
  }

  const blob = new Blob([csvContent], {
    type: "text/csv;charset=utf-8,"
  });

  const objUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", objUrl);
  link.setAttribute("download", `CLEAN-${filename}`);
  document.body.append(link);
  link.click();
  URL.revokeObjectURL(objUrl); // Revoke the object URL after clicking
  document.body.removeChild(link); // Remove the link from the DOM
}

const defaultTestRowsContent = `Chase,Mckee,Boys Athletic Director,Agawam High School,760 COOPER ST,AGAWAM,MA,01001-2195,605,C014,0016MA,4138210529,,chase@rocketalumnisolutions.com,4138210530,5,4138210529,,,,6/1/23,17240,4138210592,2.5018E+11,1,Public,,Hampden,12-Sep,1300,Orange,Brown,,,Brownies,Pioneer Valley League,,,,West,Div 2,https://www.agawamed.org/o/ahs/page/athletics,https://www.agawamed.org/o/ahs,42.074026,-72.6439206
Chase1,Mckee,Boys Athletic Director,Quabbin Regional Senior High School,800 SOUTH ST,BARRE,MA,01005-8906,001,R002,0025MA,9783555041,,chasemckee123@gmail.com,9783554651,3,,,9783555041,,8/24/15,27030,9783550163,2.5E+11,E,Public,,Worcester,,969,Blue,Gold,,,Panthers,Midland Wachusett League Division C,,,,West,Div 2,http://www.qrsd.org/our-schools/high-school/athletics/about-quabbin-athletics/,http://www.qrsd.org/our-schools/high-school/,42.40060889999999,-72.1149375
Brianna,Williams,Boys Athletic Director,Belchertown High School,142 SPRINGFIELD RD,BELCHERTOWN,MA,01007-9038,426,R005,0027MA,4133239419,8,brianna@rocketalumnisolutions.com,4133239419,0,,,,,3/14/19,29356,4133239406,2.50243E+11,1,Public,,Hampshire,,800,Orange,Black,,,Orioles,Pioneer Valley League,,,,West,Div 2,http://www.belchertownps.org/belchertown-high-school/athletics,http://www.belchertownps.org/belchertown-high-school,42.266482,-72.4101518
Brianna1,Williams,Boys Athletic Director,Chicopee High School,820 FRONT ST,CHICOPEE,MA,01020-1722,203,C036,0100MA,4135943574,,brianna@rocketalumnisolutions.com,4135943437,,4135943574,,,,9/7/23,101928,4135943500,2.50366E+11,F,Public,820 Front Street,Hampden,12-Sep,1150,Maroon,Gold,,,Pacers,Pioneer Valley League,,,,West,Div 2,https://pacerathletics.org/,https://chs.chicopeeps.org/,42.14955,-72.58608
Maddy,Holobinko,Boys Athletic Director,Chicopee Comprehensive High School,617 MONTGOMERY ST,CHICOPEE,MA,01020-1928,179,C033,0101MA,4135943574,,maddy@rocketalumnisolutions.com,4135943534,,4135943574,,4135943439,,10/26/18,102856,4135943492,2.50366E+11,F,Public,617 Montgomery Street,Hampden,12-Sep,1225,Royal,Gold,,,Colts,Pioneer Valley League,,,,West,Div 1,https://www.chicopeeps.org/athletics-ca110258,https://comp.chicopeeps.org/,42.17672,-72.59217
Maddy1,Holobinko,Boys Athletic Director,Hampden Charter School of Science High School,20 JOHNSON RD,CHICOPEE,MA,01022-1065,201,C051,0489MA,4135939090,135,mholobinko10@gmail.com,4135939090,1,4135939090,135,,,11/15/21,861909,4132942648,2.50052E+11,1,Charter,20 Johnson Road,Hampden,12-Sep,500,Navy,Maroon,,,Colts,Pioneer Valley League,,,,West,Div 1,https://www.chicopeeps.org/athletics-ca110258,https://comp.chicopeeps.org/,42.17672,-72.59217`;

export default {
  data() {
    return {
      blacklists: [],
      listsToFilter: [],
      injectTestRows: false,
      isGenerating: false,
      errorMessages: [],
      testRows: defaultTestRowsContent,
    };
  },
  methods: {
    async addBlacklist(event) {
      const onComplete = (content, file) => {
        this.blacklists.push({
          name: file.name,
          content
        });
      };

      Array.from(event.target.files).forEach(file => {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = evt => {
          onComplete(evt.target.result, file);
        };
      });
    },
    removeBlacklist(blacklist) {
      this.blacklists = this.blacklists.filter(item => item !== blacklist);
    },
    async addEmailList(event) {
      const onComplete = (content, file) => {
        this.listsToFilter.push({
          name: file.name,
          content
        });
      };

      Array.from(event.target.files).forEach(file => {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = evt => {
          onComplete(evt.target.result, file);
        };
      });
    },
    removeEmailList(emailList) {
      this.listsToFilter = this.listsToFilter.filter(
        item => item !== emailList
      );
    },
    generateLists() {
      this.isGenerating = true;
      this.errorMessages = [];

      setTimeout(() => {
        this.computeGeneratedLists();
      }, 100);
    },
    computeGeneratedLists() {
      const emailsAndDomainsToRemove = new Set();

      this.blacklists.forEach(blacklist => {
        const parsedBlacklist = parseCSV(blacklist.content);

        parsedBlacklist.forEach(row => {
          emailsAndDomainsToRemove.add(row[0].toLowerCase());
        });
      });

      this.listsToFilter.forEach((list, listIndex) => {
        this.regenerateEmailList(list, emailsAndDomainsToRemove);

        if (listIndex === this.listsToFilter.length - 1) {
          this.isGenerating = false;
        }
      });
    },
    regenerateEmailList(list, emailsAndDomainsToRemove) {
      try {
        const parsedList = parseCSV(list.content);

        // Ensure emailColumnIndex is valid
        const emailColumnIndex = parsedList[0].findIndex(
          header => header.toLowerCase().includes("email")
        );

        if (emailColumnIndex === -1) {
          this.errorMesssages.push(`Email column not found in ${list.name}`);
          return;
        }

        const uniqueEmails = new Set();
        const emailsAndDomainsSet = new Set(
          [...emailsAndDomainsToRemove].map(item => item.toLowerCase())
        );

        let filteredList = parsedList.filter((row, index) => {
          if (index === 0) return true; // Always include headers

          const email = row[emailColumnIndex].toLowerCase();
          const shouldDelete =
            emailsAndDomainsSet.has(email) ||
            [...emailsAndDomainsSet].some(item => email.includes(item)) ||
            uniqueEmails.has(email);

          if (!shouldDelete) {
            uniqueEmails.add(email);
            return true;
          }

          return false;
        });

        if (this.injectTestRows) {
          const customInjectionRows = this.testRows.trim().split('\n').map(row => row.split(','));
          const headerRow = filteredList.shift();
          filteredList.unshift(...customInjectionRows);
          filteredList = [headerRow, ...filteredList];
        }

        generateCsvFromArray(filteredList, list.name);
      } catch (e) {
        this.errorMessages.push(`Error parsing ${list.name}: ${e.message}`);
      }
    }
  }
};
</script>

<style>
.email-utils {
  display: flex;
  flex-direction: column;
}

.header-site {
  padding: 30px;
  background-color: #133353;
  color: white;
}

.widget-container {
  background: rgba(0, 0, 0, 0.05);
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  text-align: left;
}

.left-column {
  width: 50%;
  padding: 0 20px;
}

.right-column {
  width: 50%;
  padding: 0 20px;
}

.generate-button {
  width: 100%;
  justify-content: center;
  margin: 0 auto;
}

li>button {
  margin-left: 10px;
}

.error {
  color: red;
  text-align: center;
}

.advanced-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.advanced-options section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-content: center;
  height: 1.5rem;
}

.test-rows {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.test-rows textarea {
  width: 100%;
  height: 100px;
}
</style>
