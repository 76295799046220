export const PAGE_SIZE = 50;

export const customerSuccessManagers = ["Mitch", "Nick", "Kevin", "Caleb"];

export const activationDateOptions = [
    { key: 'ascendingActivationDate', label: 'Most Recent', sortKey: 'activationDate', order: 'ascending' },
    { key: 'descendingActivationDate', label: 'Least Recent', sortKey: 'activationDate', order: 'descending' },
];

export const lastLoginOptions = [
    { key: 'ascendingLastLogin', label: 'Most Recent', sortKey: 'lastLogin', order: 'ascending' },
    { key: 'descendingLastLogin', label: 'Least Recent', sortKey: 'lastLogin', order: 'descending' },
];

export const lastTouchscreenHeartbeatOptions = [
    { key: 'ascendingLastTouchscreenHeartbeatTimestamp', label: 'Most Recent', sortKey: 'lastTouchscreenHeartbeatTimestamp', order: 'ascending' },
    { key: 'descendingLastTouchscreenHeartbeatTimestamp', label: 'Least Recent', sortKey: 'lastTouchscreenHeartbeatTimestamp', order: 'descending' },
];

export const siteFullscreenOptions = [
    { key: 'ascendingLastTouchscreenHeartbeatFullscreen', label: 'Fullscreen', sortKey: 'lastTouchscreenHeartbeatFullscreen', order: 'Fullscreen' },
    { key: 'descendingLastTouchscreenHeartbeatFullscreen', label: 'Not Fullscreen', sortKey: 'lastTouchscreenHeartbeatFullscreen', order: 'Not Fullscreen' },
];

export const documentCountOptions = [
    { key: 'ascendingDocuments', label: 'Lowest to Highest', sortKey: 'document', order: 'ascending' },
    { key: 'descendingDocuments', label: 'Highest to Lowest', sortKey: 'document', order: 'descending' },
];

export const siteOwnerOptions = customerSuccessManagers.map((manager, index) => ({
    key: `siteOwner-${index}`,
    label: manager,
    sortKey: 'siteOwner',
    order: manager
}));


export const siteRatingOptions = [
    { key: 'ascendingRating', label: 'Lowest to Highest', sortKey: 'rating', order: 'ascending' },
    { key: 'descendingRating', label: 'Highest to Lowest', sortKey: 'rating', order: 'descending' },
];

export const needsAttentionOptions = [
    { key: 'csAlertTrue', label: 'Yes', sortKey: 'csAlert', order: true },
    { key: 'csAlertFalse', label: 'No', sortKey: 'csAlert', order: false },
];

export const installationMediaOptions = [
    { key: 'installationMedia', label: 'Yes', sortKey: 'installationMedia', order: true },
    { key: 'noInstallationMedia', label: 'No', sortKey: 'installationMedia', order: false },
]
