<template>
  <div class="main-container">
    <!-- Main Form Content -->
    <b-form id="form-style" @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-1" label="Form To Scrape New Site">
      </b-form-group>
      <b-form-text class="instructions">
        If you’re scraping for a new client, enter a new client collection name.
        If a client already exists, enter the destination collection ID. Please
        only fill in one.
      </b-form-text>
      <div class="client-info">
        <b-form-input
          @keydown.space.prevent
          id="input-1"
          autocomplete="off"
          v-model="newClientName"
          type="text"
          placeholder="New Client Collection Name"
        >
        </b-form-input>

        <MultiSelect
          v-if="(getClientCollectionIDs || []).length != 0"
          v-model="selectedOption"
          :options="getClientCollectionIDs"
          :custom-label="customLabel"
          placeholder="Select Destination Collection ID"
          track-by="id"
          :searchable="true"
        ></MultiSelect>
      </div>
      <b-form-text v-show="destinationClientID != ''" class="instructions">
        If you want to add entries to an existing category, enter the
        destination category ID. <br />
        <strong>
          **IMPORTANT**: When scraping HOFs, you cannot input a destination
          category ID with subcategories. It must be empty or hold entries. When
          scraping rosters, you cannot input a destination category ID with
          existing entries. The category ID must be empty or hold subcategories.
        </strong>
      </b-form-text>
      <b-form-input
        id="input-3"
        autocomplete="off"
        v-model="destinationCategoryID"
        type="text"
        placeholder="Destination Category ID or URL of Destination Category"
        v-show="destinationClientID != ''"
      >
      </b-form-input>

      <b-form-text class="instructions">
        <strong>If scraping HOF:</strong> put in exact URL to HOF site that has
        the list of names. <br /><br />

        <strong>If scraping roster data:</strong> put in base URL (e.g.
        https://rwuhawks.com/sports/mens-tennis/roster) and specify (a) which
        sport to scrape and (b) how many years to scrape. For example,
        specifying 2 years would scrape the 2023 Roster and 2022 Roster. If you
        want to scrape rosters for all years, leave it blank. If you want to
        scrape from a specific year, input the URL of that year (e.g.
        https://rwuhawks.com/sports/mens-tennis/roster/2014-15) and specify how
        many years to scrape from that start year. For example, specifying 2
        years would scrape the 2014-15 and 2014-2013 rosters. If you want to
        scrape all years from a specific start year, input the URL of that year
        and leave the number of years blank.
      </b-form-text>
      <b-form-input
        id="input-4"
        autocomplete="off"
        v-model="url"
        type="text"
        placeholder="URL"
        required
      >
      </b-form-input>

      <div class="radio-buttons">
        <div class="radio-row">
          <!-- Label for "Data Type" radio buttons -->
          <b-form-group
            label="Data Type"
            class="radio-button-label"
          ></b-form-group>
          <!-- Make button + label for each option -->
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                name="radioInline-3"
                id="sidearmHOF"
                class="custom-control-input"
                value="sidearmHOF"
                v-model="dataType"
                required
              />
              <label class="custom-control-label" for="sidearmHOF"
                >Sidearm HOF</label
              >
            </div>
          </div>
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                name="radioInline-3"
                id="prestoHOF"
                class="custom-control-input"
                value="prestoHOF"
                v-model="dataType"
              />
              <label class="custom-control-label" for="prestoHOF"
                >Presto HOF</label
              >
            </div>
          </div>
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                name="radioInline-3"
                id="sidearmRoster"
                class="custom-control-input"
                value="sidearmRoster"
                v-model="dataType"
              />
              <label class="custom-control-label" for="sidearmRoster"
                >Sidearm Roster</label
              >
            </div>
          </div>
        </div>
      </div>

      <div v-if="isSidearmHOF || isPrestoHOF">
        <div class="flex-row">
          <b-form-input
            id="input-5"
            autocomplete="off"
            v-model="HOFStartFromEntry"
            type="text"
            placeholder="Input entry number to start scraping from (leave blank for all entries)"
          >
          </b-form-input>
        </div>
      </div>

      <!-- sport and number of years to scrape for rosters -->
      <div v-if="dataType === 'sidearmRoster'">
        <div class="flex-row">
          <b-form-input
            id="input-5"
            autocomplete="off"
            v-model="sport"
            type="text"
            placeholder="Sport"
            required
          >
          </b-form-input>
          <b-form-input
            id="input-6"
            autocomplete="off"
            v-model="numYearsToScrape"
            type="text"
            placeholder="Number of years to scrape (leave blank for all years)"
          >
          </b-form-input>
        </div>
      </div>
      <!-- Default Entry Layout and Alternative Names for Dates -->
      <div class="category-attributes">
        <b-form-group
          id="input-group-7"
          label="Default Entry Layout"
          label-for="input-7"
          label-class="font-weight-bold pt-0"
        >
          <b-form-input
            id="input-7"
            v-model="defaultEntryLayout"
            placeholder="DynamicEntryLayout"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-8"
          label="Event Date Alternative Name"
          label-for="input-8"
          label-class="font-weight-bold pt-0"
        >
          <b-form-input
            id="input-8"
            v-model="eventDateAlternativeName"
            placeholder="Induction"
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-9"
          label="Graduation Date Alternative Name"
          label-for="input-9"
          label-class="font-weight-bold pt-0"
        >
          <b-form-input
            id="input-9"
            v-model="graduationDateAlternativeName"
            placeholder="Graduation"
            type="text"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-form-text
        class="instructions"
        v-if="dataType === 'sidearmRoster' || dataType === 'sidearmHOF'"
      >
        Choose whichever attributes you want that exist on the site e.g.
        Position, Sport(s), Hometown, High School etc. Default for rosters is
        “Position” and “Class”. Default for HOF is "Sport(s)" and "Type"
      </b-form-text>
      <!-- Alternatives Names for Activity & Type -->
      <div class="category-attributes">
        <b-form-group
          id="input-group-10"
          label="Activity Alternative Name"
          label-for="input-10"
          label-class="font-weight-bold pt-0"
        >
          <b-form-input
            id="input-10"
            v-model="activityAlternativeName"
            :placeholder="
              isRoster ? 'Position' : isSidearmHOF ? 'Sport(s)' : 'Activity'
            "
            type="text"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-11"
          label="Type Alternative Name"
          label-for="input-11"
          label-class="font-weight-bold pt-0"
        >
          <b-form-input
            id="input-11"
            v-model="typeAlternativeName"
            :placeholder="isRoster ? 'Class' : 'Type'"
            type="text"
          ></b-form-input>
        </b-form-group>
      </div>
      <!-- Reset & Submit Buttons -->
      <b-form-group style="padding: 10px">
        <b-button
          type="submit"
          variant="outline-primary"
          style="margin-right: 5px"
          >Submit</b-button
        >
        <b-button type="reset" variant="outline-danger" style="margin-left: 5px"
          >Reset</b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import createClientCollectionMixin from "@/mixins/createClientCollection.js";
import createSiteIDMixin from "@/mixins/createSiteID.js";
import MultiSelect from "vue-multiselect";

export default {
  name: "SiteCreatorForm",
  mixins: [createClientCollectionMixin, createSiteIDMixin],
  components: {
    MultiSelect,
  },
  data() {
    return {
      newClientName: "",
      destinationClientID: "",
      destinationCategoryID: "",
      url: "",
      dataType: "sidearmHOF",
      defaultEntryLayout: "DynamicEntryLayout",
      eventDateAlternativeName: "Induction",
      graduationDateAlternativeName: "Graduation",
      activityAlternativeName: "",
      typeAlternativeName: "",
      sport: "",
      numYearsToScrape: "",
      show: true,
      HOFStartFromEntry: "",
    };
  },
  computed: {
    isRoster() {
      return this.dataType === "sidearmRoster";
    },
    isSidearmHOF() {
      return this.dataType === "sidearmHOF";
    },
    isPrestoHOF() {
      return this.dataType === "prestoHOF";
    },
    getClientCollectionIDs() {
      return this.$store.getters.getClientCollectionIDs;
    },
    selectedOption: {
      get: function () {
        const clientCollectionID = this.getClientCollectionIDs.find(
          (clientCollectionID) =>
            clientCollectionID.id === this.destinationClientID
        );
        return clientCollectionID ? clientCollectionID : "";
      },
      set: function (selectedOption) {
        this.destinationClientID = selectedOption ? selectedOption.id : "";
      },
    },
    getStagingEnvironment() {
      switch (process.env.NODE_ENV) {
        case "test":
          return "test";
        case "production":
          return "live";
        default:
          return "dev";
      }
    },
  },
  methods: {
    customLabel(option) {
      return `${option.name} : ${option.id}`;
    },
    // If activity and type are blank, set correct default depending on whether it's HOF or roster
    generateForm() {
      let form = {
        url: this.url,
        environment: this.getStagingEnvironment,
        scraperType: this.dataType,
        defaultEntryLayout: this.defaultEntryLayout,
      };
      // check that either a new client name or an existing clientID is provided
      if (this.newClientName !== "" && this.destinationClientID !== "") {
        alert(
          "Please enter either a new client collection name or an existing destination client ID"
        );
        return false;
      }
      // if a desination clientID is provdied then add the ID as well as the categoryID if provided
      if (this.destinationClientID) {
        if (this.destinationCategoryID) {
          form.destinationCategoryID = this.destinationCategoryID
            .split("/")
            .pop()
            .split("?")[0];
        }
        //otherwise add new clientName
      } else {
        form.newClientCollectionName = this.newClientName;
      }

      // adjust defaults and ifRoster check for sport and num years to scrape
      if (this.activityAlternativeName === "") {
        this.activityAlternativeName = this.isRoster
          ? "Position"
          : this.isSidearmHOF
          ? "Sport(s)"
          : "Activity";
      }
      if (this.typeAlternativeName === "") {
        this.typeAlternativeName = this.isRoster ? "Class" : "Type";
      }

      //add default names
      form.eventDateAlternativeName = this.eventDateAlternativeName;
      form.graduationDateAlternativeName = this.graduationDateAlternativeName;
      form.activityAlternativeName = this.activityAlternativeName;
      form.typeAlternativeName = this.typeAlternativeName;

      //add additional info if it is a roster
      if (this.isRoster) {
        form.sport = this.sport;
        if (this.numYearsToScrape !== "") {
          form.numYearsToScrape = parseInt(this.numYearsToScrape);
        } else {
          form.numYearsToScrape = "";
        }
      }

      if (this.isSidearmHOF || this.isPrestoHOF) {
        if (this.HOFStartFromEntry !== "") {
          form.HOFStartFromEntry = parseInt(this.HOFStartFromEntry);
        } else {
          form.HOFStartFromEntry = "";
        }
      }
      return form;
    },
    /* On submit validates the form and posts the form to DB */
    onSubmit(event) {
      event.preventDefault();
      let body = this.generateForm();
      if (body != false) {
        let path = "/scrapingLambda";
        if (this.destinationClientID != "") {
          path += `/${this.destinationClientID}`;
        }
        this.$api
          .post(path, body)
          .then((res) => {
            if (res.status == 202) {
              alert(
                "Scraping started for target. Check slack channel for updates"
              );
              this.$router.push({ path: "/" });
            }
          })
          .catch((e) => {
            alert(`Error starting scraping: ${e.response.data}`);
          });
      }
    },
    /* Resets Form */
    onReset(event) {
      event.preventDefault();
      const output = window.confirm(
        "Are you sure you want to reset your progress?"
      );
      if (output == true) {
        // TODO: Reset our form values
        this.newClientName = "";
        this.destinationClientID = "";
        this.destinationCategoryID = "";
        this.url = "";
        this.dataType = "";
        this.defaultEntryLayout = "DynamicEntryLayout";
        this.eventDateAlternativeName = "Induction";
        this.graduationDateAlternativeName = "Graduation";
        this.activityAlternativeName = "";
        this.typeAlternativeName = "";
        this.sport = "";
        this.numYearsToScrape = "";
        this.HOFStartFromEntry = "";

        // Trick to reset/clear native browser form validation state
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
form {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.client-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

input {
  height: 40px;
  width: 500px;
  padding: 5px 5px;
  margin: 5px 0px;
  border-radius: 5px;
}

.instructions {
  text-align: left;
  font-size: 14px;
  margin: 10px 0px;
}

.radio-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px;
}
.radio-row {
  display: flex;
  flex-direction: row;
}

.radio-button-label {
  font-weight: bold;
  margin-right: 10px;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.category-attributes {
  margin: 10px 0;
  text-align: left;
}
.multiselect {
  margin-left: 10px;
  width: 500px;
}

@media screen and (max-width: 780px) {
}
</style>
